/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import { NoAuthTemplate } from 'themes';
import { Async } from 'components/layouts';
import { useDispatch } from 'react-redux';
import { userCheck } from 'redux/auth/actions';
import ReduxToastr from 'react-redux-toastr';
import { AppContext } from 'helpers';
import moment from 'moment';
import NepaliDate from 'nepali-date';
import { MONTH_LIST, NEPAL_MONTH_LIST, NEPAL_TZ } from 'constant';
import { ToastContainer } from 'react-toastify';
import getRoutes from './routes';
import Analytics from '../components/web-services/firebase';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [branch, setBranch] = useState('All');
  const [client, setClient] = useState('All');
  const [fromToDate, setFromToDate] = useState({
    type: 'today',
    f: moment(new Date()).format('yyyy-MM-DD'),
    t: moment(new Date()).format('yyyy-MM-DD'),
  });
  const [timezone, setTimezone] = useState<string>('');
  const [monthList, setMonthList] = useState<any>([]);
  const [localAuth, setLocalAuth] = useState<any>();
  const settings = JSON.parse(localStorage.getItem('tvs.settings') || '{}');
  const authData = JSON.parse(localStorage.getItem('tvs.token_data') || '{}');
  const [showListingsSubmenu, setShowListingsSubmenu] = useState<boolean>(false);
  const [showAllocationsSubmenu, setShowAllocationsSubmenu] = useState<boolean>(false);
  const [showReportsSubmenu, setShowReportsSubmenu] = useState<boolean>(false);
  const [showCollectionsSubmenu, setShowCollectionsSubmenu] = useState<boolean>(false);
  const [showIncentiveSubmenu, setShowIncentiveSubmenu] = useState<boolean>(false);
  const [showAdministrationSubmenu, setShowAdministrationSubmenu] = useState<boolean>(false);
  const [showLegalSubmenu, setShowLegalSubmenu] = useState<boolean>(false);
  const [showYardSubmenu, setShowYardSubmenu] = useState<boolean>(false);
  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [ptpFromToDate, setPtpFromToDate] = useState({
    type: 'none',
    f: '',
    t: '',
  });
  const [selectedStaffRoles, setSelectedStaffRoles] = useState<any>([]);
  const [selectedGeoAccuracies, setSelectedGeoAccuracies] = useState<any>([]);
  const [selectedPaymentNotes, setSelectedPaymentNotes] = useState<any>([]);
  const [group, setGroup] = useState('All');
  const [selectedDate, setSelectedDate] = useState(timezone === NEPAL_TZ ? new NepaliDate() : new Date());
  const [notificationsRetrieved, setNotificationsRetrieved] = useState(false);

  useEffect(() => {
    if (Analytics.analyticsEnabled) {
      Analytics.logAnalyticsEvent(Analytics.EVENT_TYPE.general.appLoad, {}, {});
    }
    if (location.hash === '#help') {
      (window as any).zE('webWidget', 'open');
    }
  }, []);

  useEffect(() => {
    if ((authData && !localAuth) || (authData && authData.acc_token !== localAuth.acc_token)) {
      setLocalAuth(authData);
      if (authData?.role && authData?.role === 'A') {
        setBranch(authData.branch);
      }
    }
  }, [authData]);

  useEffect(() => {
    if (settings.timezone) {
      setTimezone(settings.timezone);
    }
  }, [settings]);

  useEffect(() => {
    let currentTime: any = {};
    if (timezone === NEPAL_TZ) {
      const now = new NepaliDate();
      currentTime = {
        currentMonth: now.format('MM'),
        currentYear: now.format('YYYY'),
      };
      setMonthList(NEPAL_MONTH_LIST);
    } else {
      currentTime = {
        currentMonth: moment().format('MM'),
        currentYear: moment().format('Y'),
      };
      setMonthList(MONTH_LIST);
    }
    setMonth((currentTime.currentMonth - 1).toString());
    setYear(currentTime.currentYear);
  }, [timezone]);

  useEffect(() => {
    let currentTime: any = {};
    if (timezone === NEPAL_TZ) {
      const now = new NepaliDate();
      currentTime = {
        currentMonth: now.format('MM'),
        currentYear: now.format('YYYY'),
      };
      setMonthList(NEPAL_MONTH_LIST);
    } else {
      currentTime = {
        currentMonth: moment().format('MM'),
        currentYear: moment().format('Y'),
      };
      setMonthList(MONTH_LIST);
    }
    setMonth((currentTime.currentMonth - 1).toString());
    setYear(currentTime.currentYear);
  }, [timezone]);

  useEffect(() => {
    if (timezone === NEPAL_TZ) {
      const today = new NepaliDate();
      if (today.getMonth() === Number(month) && today.getYear() === Number(year)) {
        setSelectedDate(new NepaliDate());
      } else {
        setSelectedDate(new NepaliDate(Number(year), Number(month), 1));
      }
    } else {
      const today = new Date();
      if (today.getMonth() === Number(month) && today.getFullYear() === Number(year)) {
        setSelectedDate(new Date());
      } else {
        setSelectedDate(new Date(Number(year), Number(month), 1));
      }
    }
  }, [month, year]);

  const routeRenderer = (Layout: FC, pageComponent: string, noAuth = false) => {
    if (!noAuth) {
      dispatch(userCheck({}, ''));
    }
    return (
      <Layout>
        <Async page={pageComponent} />
      </Layout>
    );
  };

  const routeListRenderer = useCallback(() => {
    const availabelRoutes = getRoutes(authData?.role);
    return availabelRoutes.map(({ noAuth, path, pageComponent, layout, isExact = false }, index) => {
      const template = layout || NoAuthTemplate;
      return (
        <Route
          key={`${pageComponent}-${index}-root`}
          path={path}
          exact={isExact}
          render={() => routeRenderer(template, pageComponent, noAuth)}
        />
      );
    });
  }, [authData]);

  return (
    <AppContext.Provider
      value={{
        month,
        year,
        setMonth,
        setYear,
        timezone,
        monthList,
        branch,
        setBranch,
        client,
        setClient,
        fromToDate,
        setFromToDate,
        showListingsSubmenu,
        setShowListingsSubmenu,
        showAllocationsSubmenu,
        setShowAllocationsSubmenu,
        showReportsSubmenu,
        setShowReportsSubmenu,
        showCollectionsSubmenu,
        setShowCollectionsSubmenu,
        showIncentiveSubmenu,
        setShowIncentiveSubmenu,
        showAdministrationSubmenu,
        setShowAdministrationSubmenu,
        showLegalSubmenu,
        setShowLegalSubmenu,
        showYardSubmenu,
        setShowYardSubmenu,
        selectedBranches,
        setSelectedBranches,
        ptpFromToDate,
        setPtpFromToDate,
        selectedStaffRoles,
        setSelectedStaffRoles,
        selectedGeoAccuracies,
        setSelectedGeoAccuracies,
        selectedPaymentNotes,
        setSelectedPaymentNotes,
        group,
        setGroup,
        selectedDate,
        setSelectedDate,
        notificationsRetrieved,
        setNotificationsRetrieved,
      }}
    >
      <LoadScript googleMapsApiKey="AIzaSyAgVnPN7QnkmxQPpomRTgyxQdps6s3rHIU">
        <div className="App">
          <Switch>{routeListRenderer()}</Switch>
          <ReduxToastr
            timeOut={5000000000000}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </div>
      </LoadScript>
      <ToastContainer
        style={{
          zIndex: '99999',
          top: '20px',
        }}
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </AppContext.Provider>
  );
};

export default withRouter(App);
